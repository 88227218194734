import React, { FunctionComponent } from 'react';
import { Alert } from '@havenengineering/module-owners-shared-library/dist/components/Alert';

import styles from './NoEvents.module.scss';

export const NoEvents: FunctionComponent = () => (
  <div className={styles.noEventsContainer}>
    <Alert severity="warning">
      We&apos;ve not yet scheduled Owner Events for the next 7 days, please
      check back later.
    </Alert>
  </div>
);
