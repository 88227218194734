import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import {
  formatIsoToLocal,
  secondsToWdhms,
  zeroPad,
} from '../../helpers/dateTime';
import { Park } from '../../types/parks';
import styles from './ParkOpenCounter.module.scss';

interface ParkOpenCounterProps {
  className?: string;
  park: Park;
}

const ParkOpenCounter: FunctionComponent<ParkOpenCounterProps> = ({
  className,
  park: {
    parkName,
    countdownTitle: title,
    countdownDescription: description,
    countdownShowDescription: showDescription,
    countdownDateTime,
  },
}) => {
  const timerRef = useRef<any>(undefined);
  const [remainingTime, setRemainingTime] = useState(
    DateTime.fromISO(countdownDateTime || '').diff(DateTime.now().toUTC())
  );

  useEffect(() => {
    if (!timerRef.current && remainingTime.as('seconds') > 0) {
      timerRef.current = setInterval(
        () =>
          setRemainingTime((remainingTime) =>
            remainingTime.minus({ seconds: 60 })
          ),
        60000
      );
    }
    return () => clearInterval(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (remainingTime.as('seconds') <= 0) {
      clearInterval(timerRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  const time = secondsToWdhms(remainingTime.as('seconds'));

  return (
    <div className={clsx([styles.openCounterWrapper, className])}>
      <h5>PARK NEWS / ANNOUNCEMENT</h5>
      <h2>{parkName}</h2>
      <h3>{title}</h3>

      <>
        <div className={styles.detailsGrid}>
          <div className={styles.dateTime}>
            <img src="/assets/icon-clean-calendar.svg" alt="clean-calendar" />
            <span>{`${formatIsoToLocal(
              countdownDateTime || '',
              'ccc d LLL'
            )}`}</span>
          </div>
          <div className={styles.dateTime}>
            <img src="/assets/icon-clock.svg" alt="clock" />
            <span>{`${formatIsoToLocal(
              countdownDateTime || '',
              'HH:mm'
            )}`}</span>
          </div>
        </div>

        <div className={styles.counterGrid}>
          <div>
            <p>{time ? zeroPad(time.weeks, 2) : '00'}</p>
            <span>Weeks</span>
          </div>
          <p>:</p>
          <div>
            <p>{time ? zeroPad(time.days, 2) : '00'}</p>
            <span>Days</span>
          </div>
          <p>:</p>
          <div>
            <p>{time ? zeroPad(time.hours, 2) : '00'}</p>
            <span>Hours</span>
          </div>
          <p>:</p>
          <div>
            <p>{time ? zeroPad(time.minutes, 2) : '00'}</p>
            <span>Minutes</span>
          </div>
        </div>
      </>

      {showDescription && (
        <div dangerouslySetInnerHTML={{ __html: description || '' }} />
      )}
    </div>
  );
};

export default ParkOpenCounter;
