import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { Activity, ActivityDetails } from '../../types/activities';
import styles from './Activities.module.scss';
import { ActivityComponent } from './Activity';
import { NoActivities } from './NoActivities';
import { NoEvents } from './NoEvents';
import { OwnersTitle } from './OwnersTitle';
interface Props {
  activities: ActivityDetails[] | Activity[];
  owners: boolean;
}

const Activities: FunctionComponent<Props> = ({ activities, owners }) => {
  const ActivitiesList = () =>
    activities.map((item) => (
      <ActivityComponent activity={item} key={item.id} />
    ));
  return (
    <div
      className={clsx(styles.activitiesContainer, {
        [styles.onlyOwners]: owners,
      })}
    >
      {owners && <OwnersTitle />}
      {activities.length > 0 ? (
        ActivitiesList()
      ) : !owners ? (
        <NoActivities />
      ) : (
        <NoEvents />
      )}
    </div>
  );
};

export default Activities;
