import getConfig from 'next/config';

import { fetchWrapper, withApiBaseUrl } from '../../helpers/fetch';
import { Activity, ActivityDetails } from '../../types/activities';

const {
  publicRuntimeConfig: { PUBLIC_ENV },
} = getConfig();

export const fetchUpComingEvents = async (
  accountNo: string | undefined,
  parkCode: string | undefined
) => {
  const NUMBER_OF_DISPLAYED_ACTIVITIES = 4;
  const NUMBER_OF_DISPLAYED_OWNERS_EVENTS = 3;
  try {
    const upcomingEventsPromise = fetchWrapper(
      withApiBaseUrl(`/activities?accountNumber=${accountNo}`),
      {
        method: 'GET',
        credentials: 'include',
      }
    );
    const ownersEventsPromise = fetchWrapper(
      withApiBaseUrl(
        `/activities/owners?parkCode=${parkCode}&limit=${NUMBER_OF_DISPLAYED_OWNERS_EVENTS}`
      ),
      {
        method: 'GET',
        credentials: 'include',
      }
    );
    const [upComingEventsData, ownersEventsData]: [
      ActivityDetails[],
      Activity[]
    ] = await Promise.all([upcomingEventsPromise, ownersEventsPromise]);
    return {
      booked: filterAndOrderActivities(
        upComingEventsData,
        NUMBER_OF_DISPLAYED_ACTIVITIES
      ),
      owners: ownersEventsData,
      isLoaded: true,
      hasError: false,
    };
  } catch (error: any) {
    if (PUBLIC_ENV !== 'development') {
      console.error(error);
    }
    return {
      booked: [],
      owners: [],
      isLoaded: true,
      hasError: true,
    };
  }
};

export const filterAndOrderActivities = (
  activities: ActivityDetails[],
  limit: number
) => {
  return activities
    .filter((a) => !a.isCancelled)
    .sort(
      (a, b) =>
        new Date(a.timeslot.zamStarts).getTime() -
        new Date(b.timeslot.zamStarts).getTime()
    )
    .slice(0, limit);
};
