import React, { FunctionComponent, useEffect, useState } from 'react';
import getConfig from 'next/config';
import { Alert } from '@havenengineering/module-owners-shared-library/dist/components/Alert';
import { SectionHeader } from '@havenengineering/module-owners-shared-library/dist/components/SectionHeader';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator/LoadingIndicator';
import clsx from 'clsx';

import { Activity, ActivityDetails } from '../../types/activities';
import Activities from './Activities';
import { fetchUpComingEvents } from './UpComingEvents.helpers';
import styles from './UpComingEvents.module.scss';

const {
  publicRuntimeConfig: { PUBLIC_ACTIVITIES_APP_URL },
} = getConfig();

interface upComingEvents {
  booked: ActivityDetails[];
  owners: Activity[];
  isLoaded: boolean;
  hasError: boolean;
}

export const UpComingEvents: FunctionComponent = () => {
  const { activeAccount } = useAuthContext();

  const upComingActivitiesState: upComingEvents = {
    booked: [],
    owners: [],
    isLoaded: false,
    hasError: false,
  };

  const [upComingActivities, setUpComingActivities] = useState(
    upComingActivitiesState
  );

  useEffect(() => {
    fetchUpComingEvents(activeAccount?.accountNo, activeAccount?.parkCode).then(
      (response) => {
        setUpComingActivities(response);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(styles.gutterBottom, styles.gutterTop)}>
      <SectionHeader
        title="Activities & Owner Events"
        extraContent={
          <a
            className={styles.sectionHeaderLink}
            href={`${PUBLIC_ACTIVITIES_APP_URL}/activity-list?tab=bookings`}
          >
            See all bookings
          </a>
        }
      />
      {!upComingActivities.isLoaded ? (
        <div
          className={clsx(styles.loadingContainer, styles.activitiesLoading)}
          data-testid="upComingEventLoader"
        >
          <LoadingIndicator loading />
        </div>
      ) : !upComingActivities.hasError ? (
        <div className={styles.activitiesWrapper}>
          <Activities activities={upComingActivities.booked} owners={false} />
          <Activities activities={upComingActivities.owners} owners={true} />
        </div>
      ) : (
        <Alert severity="error">
          We have encountered a technical problem and your bookings cannot be
          displayed at the moment.
          <br />
          Please reload the page, and if the problem persist contact the system
          administrator.
          <br />
          We apologise for any inconvenience.
        </Alert>
      )}
    </div>
  );
};
