import { RefObject } from 'react';

import { useViewportRelation } from './useViewportRelation';

export const useOnScreen = (
  ref: RefObject<HTMLDivElement>,
  { threshold = 0, rootMargin = '0px', enable = true } = {}
): boolean => {
  return (
    useViewportRelation(ref, { threshold, rootMargin, enable }) === 'inside'
  );
};

export default useOnScreen;
