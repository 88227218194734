import React, { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { MarketingBanner } from '@havenengineering/module-owners-shared-library/dist/components/MarketingBanner';

import {
  addParamsToContentfulImage,
  transformImageParams,
} from '../helpers/contentful-image-api';
import HomepageBanner from './HomepageBanner/HomepageBanner';

const isHomepageBanner = (banner: Banner): banner is HomepageBanner =>
  banner.type === 'homepage';
const isMarketingBanner = (banner: Banner): banner is MarketingBanner =>
  banner.type === 'marketing';

export interface BannerProps {
  banners: Banner[];
}

const BannerList: FunctionComponent<BannerProps> = ({ banners }) => {
  const router = useRouter();

  const ctaOnClick = (ctaUrl: string) => {
    if (ctaUrl.startsWith('http')) {
      window.open(ctaUrl, '_blank');
    } else if (ctaUrl.startsWith('/')) {
      router.push(ctaUrl);
    }
  };

  const renderBanner = (banner: Banner, withGutterBottom: boolean) => {
    if (isHomepageBanner(banner)) {
      return (
        <HomepageBanner
          key={banner.data.id}
          banner={banner.data}
          handleCtaClick={() => ctaOnClick(banner.data.ctaUrl)}
        />
      );
    } else if (isMarketingBanner(banner)) {
      return (
        <MarketingBanner
          key={banner.data.id}
          heading={banner.data.heading}
          text={banner.data.text}
          label={banner.data.label}
          imageUrl={
            banner.data?.image &&
            addParamsToContentfulImage(
              banner.data?.image,
              transformImageParams({
                width: 600,
                quality: 95,
              })
            )
          }
          mobileImageUrl={
            banner.data?.mobileImage &&
            addParamsToContentfulImage(
              banner.data?.mobileImage,
              transformImageParams({
                width: 600,
                quality: 95,
              })
            )
          }
          ctaText={banner.data.ctaText}
          handleCtaClick={() => ctaOnClick(banner.data.ctaUrl)}
          gutterBottom={withGutterBottom}
          palette={banner.data.backgroundColor}
        />
      );
    }
  };

  return (
    <>
      {banners.map((banner, idx) =>
        renderBanner(banner, idx !== banners.length - 1)
      )}
    </>
  );
};

export default BannerList;
