import { useState } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import SelectInput from '@havenengineering/module-shared-library/dist/form/components/SelectInput';

import { AccountUpdate } from '../Let2offsetApplication';
import styles from './Let2OwnWarningModalContent.module.scss';

export enum OptOutReason {
  stopLetting = 'STOP_LETTING',
  propositionChange = 'PROPOSITION_CHANGE',
  circumstancesChange = 'CIRCUMSTANCES_CHANGE',
}

interface Let2OwnWarningModalContentProps {
  activeAccount: UserAccount;
  handleLetToOwnRenewal: (
    accountUpdate: AccountUpdate,
    redirectUrl: string
  ) => Promise<void>;
}

export const Let2OwnWarningModalContent = ({
  handleLetToOwnRenewal,
  activeAccount,
}: Let2OwnWarningModalContentProps) => {
  const [optOutReason, setOptOutReason] = useState<OptOutReason>();

  return (
    <div className={styles.let2OwnModal}>
      <p>
        Add your breaks to continue with Let2offset and offset your letting
        income against your site fees.
      </p>
      <p>
        <b>
          Please note: if you don&apos;t add your breaks and decide to opt out,
          you won&apos;t be eligible to join Let2offset again.
        </b>
      </p>
      <p data-testid="modal-let2own-continue-text">
        Would you like to continue with Let2offset?
      </p>
      <div className={styles.buttonContainer}>
        <Button
          type="button"
          onClick={() =>
            handleLetToOwnRenewal(
              {
                accountNumber: activeAccount?.accountNo as string,
                isRenewing: true,
                optOutReason: optOutReason,
              },
              '/bookings'
            )
          }
          data-testid="modal-let2own-add-breaks"
        >
          Yes, I want to add my letting breaks
        </Button>
      </div>
      <div className={styles.optOut}>
        <SelectInput
          narrow
          capitaliseOptions={false}
          label="Please select an opt out reason:"
          options={[
            {
              label: 'We don’t want to let our holiday home',
              value: OptOutReason.stopLetting,
            },
            {
              label:
                'We’re going to use the Fixed or Flexi letting products instead',
              value: OptOutReason.propositionChange,
            },
            {
              label: 'We’ve had a change of circumstances',
              value: OptOutReason.circumstancesChange,
            },
          ]}
          id="opt-out-reason"
          value={optOutReason as string}
          onChange={(value) => value && setOptOutReason(value as OptOutReason)}
        />
        <Button
          data-testid="modal-let2own-cancel"
          disabled={!optOutReason}
          onClick={() =>
            handleLetToOwnRenewal(
              {
                accountNumber: activeAccount?.accountNo as string,
                isRenewing: false,
                readyForRenewal: false,
                optOutReason,
              },
              '/letting'
            )
          }
          variant="outlined"
        >
          No, I would like to opt out
        </Button>
      </div>
    </div>
  );
};
