import { stringify } from 'querystring';

export type ContentfulImageFitTypes =
  | 'pad'
  | 'fill'
  | 'scale'
  | 'crop'
  | 'thumb';

export type ContentfulImageFocusTypes =
  | 'center'
  | 'top'
  | 'right'
  | 'left'
  | 'bottom'
  | 'top_right'
  | 'top_left'
  | 'bottom_right'
  | 'bottom_left'
  | 'face'
  | 'faces';

export type ImageParams = {
  width?: number;
  height?: number;
  quality?: number;
  format?: 'jpg' | 'webp' | 'avif';
  fit?: ContentfulImageFitTypes;
  focusType?: ContentfulImageFocusTypes;
};

export const transformImageParams = ({
  width,
  height,
  quality = 90,
  fit = 'fill',
  focusType = 'center',
  format,
}: ImageParams) => ({
  ...(width ? { w: width } : {}),
  ...(height ? { h: height } : {}),
  q: quality,
  fit,
  f: focusType,
  ...(format ? { fm: format } : {}),
});

export const addParamsToContentfulImage = (
  url: string,
  params?: ReturnType<typeof transformImageParams>
): string => {
  if (!params || !Object.entries(params)) {
    return url;
  }

  const queryString = stringify(params);

  return `${url}?${queryString}`;
};
