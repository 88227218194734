import React, { FunctionComponent } from 'react';
import getConfig from 'next/config';
import {
  Badge,
  BadgeTheme,
} from '@havenengineering/module-owners-shared-library/dist/components/Badge';
import clsx from 'clsx';

import { formatIsoToLocal } from '../../helpers/dateTime';
import { Activity, ActivityDetails, TimeSlot } from '../../types/activities';
import { isActivityDetails } from './Activity.helpers';
import styles from './Activity.module.scss';

const {
  publicRuntimeConfig: { PUBLIC_ACTIVITIES_APP_URL },
} = getConfig();

interface Props {
  activity: ActivityDetails | Activity;
}

export const ActivityComponent: FunctionComponent<Props> = ({ activity }) => {
  let title: string;
  let zamStarts: string;
  let timeSlots: TimeSlot[];
  let url: string;
  const onlyOwners = !isActivityDetails(activity);
  if (isActivityDetails(activity)) {
    title = activity.activity.title;
    zamStarts = activity.timeslot.zamStarts;
    timeSlots = [activity.timeslot];
    url = `activity-list?tab=bookings&id=${activity.id}`;
  } else {
    title = activity.title;
    zamStarts = activity.timeSlots[0].zamStarts;
    timeSlots = activity.timeSlots;
    url = `activity-list/${activity.id}?date=${formatIsoToLocal(
      zamStarts,
      'yyyy-MM-dd'
    )}`;
  }

  return (
    <div
      className={clsx(styles.activityContainer, {
        [styles.notOwners]: !onlyOwners,
      })}
      data-testid={'activity#' + activity.id}
    >
      <a href={`${PUBLIC_ACTIVITIES_APP_URL}/${url}`}>
        <h3 className={clsx(styles.link, styles.title)}>
          <span className={styles.underlined}>{title}</span>
          <img
            className={styles.chevron}
            src="/assets/icon-navchevron-down.svg"
            alt=""
          />
        </h3>
      </a>
      <div className={styles.details}>
        <Badge text="ACTIVITY" theme={BadgeTheme.BLUE_DARK_75} />

        <div className={styles.dateTime}>
          <img src="/assets/icon-clean-calendar.svg" alt="" />
          <span>{`${formatIsoToLocal(zamStarts, 'ccc d LLL')}`}</span>
        </div>
        <div className={clsx(styles.dateTime, styles.separator)}>|</div>
        <div className={clsx(styles.dateTime, styles.timeSlots)}>
          <img src="/assets/icon-clock.svg" alt="" />
          {timeSlots.map((timeSlot, index) => {
            if (index <= 2) {
              return (
                <div key={`${timeSlot.id}-${index}`}>
                  <span className={styles.extraTimeSlots}>
                    {index !== 0 ? ', ' : ''}
                  </span>
                  <span>{`${formatIsoToLocal(
                    timeSlot.zamStarts,
                    'HH:mm'
                  )}`}</span>
                </div>
              );
            }
            if (index === 3) {
              return (
                <span className={styles.extraTimeSlots} key={index}>
                  ...
                </span>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
