import React, { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';

const SummerAnimation = dynamic<{ inline?: boolean }>(
  () =>
    import(
      '@havenengineering/module-owners-shared-library/dist/components/SeasonalAnimations/Summer'
    ).then((mod) => mod.Summer),
  { ssr: false }
);

const AutumnAnimation = dynamic<{ inline?: boolean }>(
  () =>
    import(
      '@havenengineering/module-owners-shared-library/dist/components/SeasonalAnimations/Autumn'
    ).then((mod) => mod.Autumn),
  { ssr: false }
);

const SpringAnimation = dynamic<{ inline?: boolean; easter?: boolean }>(
  () =>
    import(
      '@havenengineering/module-owners-shared-library/dist/components/SeasonalAnimations/Spring'
    ).then((mod) => mod.Spring),
  { ssr: false }
);

const WinterAnimation = dynamic<{ inline?: boolean; xmas?: boolean }>(
  () =>
    import(
      '@havenengineering/module-owners-shared-library/dist/components/SeasonalAnimations/Winter'
    ).then((mod) => mod.Winter),
  { ssr: false }
);

interface SeasonalBannerAnimationsProps {
  animation: string;
}

export const SeasonalBannerAnimations: FunctionComponent<
  SeasonalBannerAnimationsProps
> = ({ animation }) => {
  const renderAnimation = () => {
    switch (animation) {
      case 'summer':
        return <SummerAnimation />;
      case 'autumn':
        return <AutumnAnimation />;
      case 'spring':
        return <SpringAnimation />;
      case 'easter':
        return <SpringAnimation easter />;
      case 'winter':
        return <WinterAnimation />;
      case 'xmas':
        return <WinterAnimation xmas />;
      default:
        return <></>;
    }
  };

  return renderAnimation();
};
