import React, { FunctionComponent, useMemo } from 'react';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator/LoadingIndicator';
import clsx from 'clsx';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { swrFetcher, swrLocalFetcher } from '../../../helpers/fetch';
import SingleRead from '../SingleRead/SingleRead';
import styles from './ImportantReads.module.scss';

interface Props {
  onHub?: boolean;
  parkCode: string;
  filterToImportant?: boolean;
}

const NUMBER_OF_DISPLAYED_ARTICLES = {
  HUB: 2,
  NEWS: 6,
};

const ImportantReads: FunctionComponent<Props> = ({
  onHub = false,
  parkCode,
  filterToImportant = false,
}) => {
  const { data: articles } = useSWRImmutable<Article[]>(
    `/contentful/articles?parkCode=${parkCode}&filterToImportant=${filterToImportant}`,
    swrLocalFetcher
  );
  const { data } = useSWR<{ articlesRead: string[] }>(
    '/contentful/articles-read',
    swrFetcher
  );
  const processedArticles = useMemo(
    () =>
      (onHub && articles
        ? articles.filter(
            (article) => !data?.articlesRead?.includes(article.id)
          )
        : articles || []
      ).slice(0, NUMBER_OF_DISPLAYED_ARTICLES[onHub ? 'HUB' : 'NEWS']),
    [articles, data, onHub]
  );

  return (
    <>
      {!articles ? (
        <div className={clsx(styles.loadingContainer, styles.newsLoading)}>
          <LoadingIndicator loading />
        </div>
      ) : (
        <div
          className={clsx(
            styles.importantReadContainer,
            onHub && styles.flexDisplay
          )}
        >
          {processedArticles.length > 0 ? (
            processedArticles.map((item, idx) => (
              <SingleRead
                article={item}
                bottomBorder={onHub || idx < processedArticles.length - 1}
                key={item.id}
              />
            ))
          ) : (
            <p className="owners-body">You’re all up to date</p>
          )}
        </div>
      )}
    </>
  );
};

export default ImportantReads;
