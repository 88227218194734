import React, { FunctionComponent } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import clsx from 'clsx';

import styles from './LettingAdvertisementDialog.module.scss';

interface Props {
  lastYearOfLet: number;
  userName?: string;
  handleOk: () => void;
  handleClose: () => void;
}

export const LettingAdvertisementDialog: FunctionComponent<Props> = ({
  lastYearOfLet,
  userName,
  handleOk,
  handleClose,
}) => {
  return (
    <div className={styles.AdRoot}>
      <div className={styles.Ad}>
        <div className={styles.AdImage} />
        <div className={clsx(styles.AdContent, 'owners-body')}>
          <h1 className="owners-heading-1">We miss you!</h1>
          <p>
            {userName ? `${userName}, a` : 'A'} lot has changed with Haven
            Simply letting since {lastYearOfLet}.
          </p>
          <p>
            Our letting income is now better than ever and 95% of bookings we
            take now come directly through Haven with the rest coming from long
            term valued partners like Hoseasons. We&apos;ve improved our Repair
            Cover Plan, covering more than before. And we still give you a £325
            letting bonus when you let for 12 breaks, 6 which are on peak dates.
          </p>
          <p>
            <b>
              Sound interesting? Find out all about our improved letting service
            </b>
          </p>
          <Button onClick={() => handleOk()}>Haven Simply Letting</Button>
          <Button
            variant="text"
            className={styles.AdClose}
            onClick={() => handleClose()}
            data-testid="close-ad"
          >
            <img
              src="https://www.haven.com/assets/icons/close.svg"
              width={16}
              height={16}
              alt="close"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
